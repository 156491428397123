.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(-10px);
}
.fade-appear p,
.fade-enter p {
  transform: translateY(-5px);
  opacity: 0;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
}
.fade-appear-active p,
.fade-enter.fade-enter-active p {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active p:nth-child(2),
.fade-enter.fade-enter-active p:nth-child(2) {
  transition: opacity 300ms linear 300ms, transform 300ms ease-out 300ms;
}
.fade-appear-active p:nth-child(3),
.fade-enter.fade-enter-active p:nth-child(3) {
  transition: opacity 300ms linear 400ms, transform 300ms ease-out 400ms;
}
.fade-appear-active p:nth-child(4),
.fade-enter.fade-enter-active p:nth-child(4) {
  transition: opacity 300ms linear 500ms, transform 300ms ease-out 500ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 150ms linear, transform 150ms ease-in;
}
.mapbox-gl-draw_ctrl-draw-btn {
  height: 0px !important;
  width: 0px !important;
  overflow: hidden;
}
.mapboxgl-ctrl-geolocate {
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.mapboxgl-ctrl {
  box-shadow: 0 0 0 0px rgb(0 0 0 / 10%) !important;
}

.blueInnerBlob {
  background: #0079b4;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 18px;
  width: 18px;
  transform: scale(1);
  animation: pulse-black 1.3s infinite;
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 121, 180, 1);
  }

  70% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
